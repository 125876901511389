<template>
  <navigation index="productModel" ref="navigation">
    <div>
      <div class="topnavigation">
        <div style="width: 330px">
          <el-button type="primary" @click="OpenProductBrandWindow({})">
            {{ tools.GetLanguageValue("web.新增品牌") }}</el-button
          >
        </div>
      </div>
      <div :style="{ height: ContentHeight + 'px' }">
        <div style="display: flex; justify-content: space-between">
          <div style="width: 310px">
            <el-table
              @cell-click="CheckProductBrand"
              :data="ProductBrands"
              style="width: 100%; height: 100%"
              :style="{ height: ContentHeight  + 'px' }"
            >
              <el-table-column
                :label="tools.GetLanguageValue('web.名称')"
                prop="Name"
              />

              <el-table-column
                width="80"
                :label="tools.GetLanguageValue('web.操作')"
              >
                <template #default="scope">
                  <el-button
                    size="small"
                    @click="OpenProductBrandWindow(scope.row)"
                  >
                    {{ tools.GetLanguageValue("web.编辑") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            style="width: calc(100% - 330px)"
            :style="{ height: ContentHeight - 50 + 'px' }"
          >
            <div style="height: 50px; display: flex; align-items: center">
              <el-button
                type="primary"
                v-if="ProductBrandOpt != null"
                @click="OpenProductModelWindow({ Sort: 0 })"
              >
                {{ ProductBrandOpt.Name }} -
                {{ tools.GetLanguageValue("web.新增型号") }}</el-button
              >
            </div>
            <el-table
              border
              :data="ProductModelDatas"
              style="width: 100%; height: 100%"
            >
              <el-table-column type="index" width="50"> </el-table-column>
              <el-table-column
                :label="tools.GetLanguageValue('web.名称')"
                width="180"
                prop="Name"
              />
              <el-table-column
                :label="tools.GetLanguageValue('web.编码')"
                width="180"
                prop="Code"
              />

              <!-- <el-table-column
                :label="tools.GetLanguageValue('web.分类')"
                width="180"
              >
                <template #default="scope">
                  <span v-if="scope.row.IsTemp">
                    {{ tools.GetLanguageValue("web.临时型号") }}
                  </span>
                </template>
              </el-table-column> -->

              <el-table-column
                :label="tools.GetLanguageValue('web.排序')"
                width="80"
                prop="Sort"
              />

              <el-table-column
                :label="tools.GetLanguageValue('web.备注')"
                width="180"
                prop="Description"
              />

              <el-table-column :label="tools.GetLanguageValue('web.操作')">
                <template #default="scope">
                  <el-button
                    type="success"
                    size="small"
                    @click="OpenProductModelWindow(scope.row)"
                  >
                    {{ tools.GetLanguageValue("web.编辑") }}</el-button
                  >
                  <el-button
                    size="small"
                    @click="DeleteProductModel(scope.row)"
                    type="danger"
                  >
                    {{ tools.GetLanguageValue("web.删除") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div
              style="
                height: 50px;
                display: flex;
                justify-items: center;
                gap: 10px;
                padding: 0px 10px;
              "
            >
              <el-pagination
                background
                layout="prev, pager, next"
                @current-change="(number)=>{
                  ProductModelPadding.Page = number;
                  GetProductModel(ProductBrandOpt)
                }"
                :page-size="ProductModelPadding.Size"
                :total="ProductModelPadding.Count"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>

      <!-- 添加 -->
      <el-dialog
        v-model="ProductModelWindow"
        :title="tools.GetLanguageValue('web.编辑')"
        width="800px"
        :show-close="false"
      >
        <el-form
          ref="form"
          :model="ProductModelOpt"
          label-width="80px"
          style="margin-top: 10px"
        >
          <el-form-item :label="tools.GetLanguageValue('web.名称')">
            <el-input
              v-model="ProductModelOpt.Name"
              @focus="InputSelect"
              :placeholder="tools.GetLanguageValue('web.请输入型号名称')"
            ></el-input>
          </el-form-item>

          <el-form-item :label="tools.GetLanguageValue('web.品牌')">
            <el-input
              v-model="this.ProductBrandOpt.Name"
              disabled="true"
            ></el-input>
          </el-form-item>

          <el-form-item :label="tools.GetLanguageValue('web.编号')">
            <el-input
              v-model="ProductModelOpt.Code"
              @focus="InputSelect"
              :placeholder="tools.GetLanguageValue('web.请输入编号')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="tools.GetLanguageValue('web.排序')">
            <el-input-number
              v-model="ProductModelOpt.Sort"
              @focus="InputSelect"
              :placeholder="tools.GetLanguageValue('web.请输入排序')"
            ></el-input-number>
          </el-form-item>

          <el-form-item :label="tools.GetLanguageValue('web.备注')">
            <el-input
              v-model="ProductModelOpt.Description"
              @focus="InputSelect"
              :placeholder="tools.GetLanguageValue('web.请输入备注')"
            ></el-input>
          </el-form-item>
        </el-form>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="ProductModelWindow = false">
              {{ tools.GetLanguageValue("web.关闭") }}</el-button
            >
            <el-button @click="InsertProductModel" type="primary">
              {{ tools.GetLanguageValue("web.保存") }}</el-button
            >
          </span>
        </template>
      </el-dialog>

      <!-- 添加品牌 -->
      <el-dialog
        v-model="ProductBrandWindow"
        :title="tools.GetLanguageValue('web.编辑')"
        width="800px"
        :show-close="false"
      >
        <el-tabs v-model="BrandLanguageName">
          <el-tab-pane :label="tools.GetLanguageValue('web.默认')" name="first">
            <el-form
              ref="form"
              :model="ProductBrandOpt"
              label-width="80px"
              style="margin-top: 10px"
            >
              <el-form-item :label="tools.GetLanguageValue('web.名称')">
                <el-input
                  v-model="ProductBrandOpt.Name"
                  @focus="InputSelect"
                  :placeholder="tools.GetLanguageValue('web.请输入品牌名称')"
                ></el-input>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.编号')">
                <el-input
                  v-model="ProductBrandOpt.Code"
                  @focus="InputSelect"
                  :placeholder="tools.GetLanguageValue('web.请输入编号')"
                ></el-input>
              </el-form-item>
              <el-form-item :label="tools.GetLanguageValue('web.排序')">
                <el-input
                  v-model="ProductBrandOpt.Sort"
                  @focus="InputSelect"
                  :placeholder="tools.GetLanguageValue('web.请输入排序')"
                ></el-input>
              </el-form-item>

              <el-form-item :label="tools.GetLanguageValue('web.备注')">
                <el-input
                  v-model="ProductBrandOpt.Description"
                  @focus="InputSelect"
                  :placeholder="tools.GetLanguageValue('web.请输入备注')"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane
            :label="item.LanguageName"
            :name="item.LanguageName"
            v-for="(item, index) in ProductBrandOpt.Languages"
            :key="index"
          >
            <el-form-item :label="tools.GetLanguageValue('web.商品名称')">
              <el-input
                v-model="item.Language.Name"
                @focus="InputSelect"
              ></el-input>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="ProductBrandWindow = false">
              {{ tools.GetLanguageValue("web.关闭") }}</el-button
            >
            <el-button @click="InsertProductBrand" type="primary">
              {{ tools.GetLanguageValue("web.保存") }}</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
  </navigation>
</template>
  <script >
import axios from "../../../../commons/AxiosMethod.js";
import tools from "../../../../commons/tools.js";

import { ElMessageBox, ElMessage } from "element-plus";
import ProductItem from "../../../../components/ProductItem.vue";
import navigation from "../../../../components/Modules/Navigation.vue";
import { CloseBold, Plus } from "@element-plus/icons-vue";
export default {
  // eslint-disable-next-line  @typescript-eslint/explicit-module-boundary-types aboutus
  data() {
    return {
      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,

      ProductModelOpt: {
        Sort: 0,
      },
      ProductModelWindow: false,
      ProductModelDatas: [],
      ProductModelPadding: {
        Page: 1,
        Size: 30,
        Count: 0,
        Where: {},
      },
      UploadPictureImageUrl: "",
      headersImage: {},
      ContentHeight: 800,
      ProductBrands: [],
      tools: tools,
      ProductBrandOpt: {}, //型号
      ProductBrandOpt: {},
      ProductBrandWindow: false,
      ProductBrandPadding: {
        Page: 1,
        Size: 30,
        Count: 0,
        Where: {},
      },
      BrandLanguageName: "first",
      LanguageName: "first",
    };
  },
  name: "App",
  mounted() {
    this.UploadPictureImageUrl = axios.GetUrl() + "/api/Upload/ImageFileWebp";
    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header"),
    };

    this.GetProductBrands();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 130;
        this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 130;
  },

  components: {
    navigation,
    ProductItem,
    CloseBold,
    Plus,
  },
  methods: {
    //插入品牌
    InsertProductBrand() {
      axios.apiMethod(
        "/stores/GoodsManage/InsertProductBrands",
        "post",
        this.ProductBrandOpt,
        (result) => {
          if (result.Data.IsSuccess) {
            this.ProductBrandWindow = false;
            this.GetProductBrands();
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
    //编辑品牌
    OpenProductBrandWindow(item) {
      axios.apiMethod(
        "/stores/GoodsManage/GetProductBrandDetail",
        "get",
        {
          id: item.Id,
        },
        (result) => {
          this.ProductBrandOpt = result.Data;
          this.ProductBrandWindow = true;
        }
      );
    },
    // //打开编辑窗口
    // OpenProductBrandWindow(item) {
    //   this.ProductBrandOpt = item;
    //   this.ProductBrandWindow = true;
    // },
    CheckProductBrand(row, column, cell, event) {
      this.ProductBrandOpt = row;
      this.GetProductModel(row);
    },
    // 上传成功操作
    UploadSuccess(response, file, fileList) {
      if (response.Code == 200) {
        this.ComImageUrl = response.Data.FileName;
        this.ComImageFormet = response.Data.ImageFormet;
        this.ProductModelOpt.LogoFormat = response.Data.ImageFormet;
        this.ProductModelOpt.Logo = response.Data.FileName;
      }
    },

    InputSelect(e) {
      e.currentTarget.select();
    },
    GetProductBrands() {
      const param = {};
      axios.apiMethod(
        "/stores/GoodsManage/GetProductBrands",
        "post",
        this.ProductBrandPadding,
        (result) => {
          this.ProductBrands = result.Data.Datas;
          if (this.ProductBrands.length > 0) {
            this.ProductBrandOpt = this.ProductBrands[0];
            this.GetProductModel(this.ProductBrandOpt);
          } else {
            this.ProductBrandOpt = null;
          }
        }
      );
    },
    //查询型号列表
    GetProductModel(item) {
      this.ProductModelPadding.Where.BrandId = item.Id;
      axios.apiMethod(
        "/stores/GoodsManage/GetProductModel",
        "post",
        this.ProductModelPadding,
        (result) => {
          this.ProductModelDatas = result.Data.Datas;
          this.ProductModelPadding.Page = result.Data.Page;
          this.ProductModelPadding.Size = result.Data.Size;
          this.ProductModelPadding.Count = result.Data.Count;
        }
      );
    },

    //删除 型号
    DeleteProductModel(item) {
      ElMessageBox.confirm(
        "确定要删除<span style='color:red;font-size:14pt'>[" +
          item.Name +
          "]</span>吗" +
          "?",
        "提示",
        {
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          axios.apiMethod(
            "/stores/GoodsManage/DeleteProductModel",
            "get",
            {
              productModelId: item.Id,
            },
            (result) => {
              if (result.Data.IsSuccess) {
                this.ProductModelWindow = false;
                this.GetProductModel();
              } else {
                ElMessageBox({
                  title: "提示",
                  message: "设置失败",
                  type: "error",
                });
              }
            }
          );
        })
        .catch(() => {});
    },

    //打开编辑窗口
    OpenProductModelWindow(item) {
      this.ProductModelOpt = item;
      this.ProductModelWindow = true;
    },
    //插入型号
    InsertProductModel() {
      this.ProductModelOpt.BrandId = this.ProductBrandOpt.Id;
      axios.apiMethod(
        "/stores/GoodsManage/InsertProductModel",
        "post",
        this.ProductModelOpt,
        (result) => {
          if (result.Data.IsSuccess) {
            this.ProductModelWindow = false;
            this.GetProductModel(this.ProductBrandOpt);
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
  },
};
</script>
  
  <style scoped lang="less">
.inputSKU {
  width: 100%;
  border: 0px;
}

.tableSKU {
  tr {
    td {
      padding: 5px;
      height: 40px;
    }
  }
}
</style>
  